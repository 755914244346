<template>
	<div class="goodslist">
		<!-- 横向布局 -->
		<div class="xc_theme_goods_box" v-if="type==1">
			<div class="goodsobj" v-for="(item,index) in goodsList" :key="index" @click="navTozhuanqu(index)">
				<img class="avatar" :src="item.medicineDrugs.mainImages[0]" />
				<div class="conbox">
					<div class="text1 clamp2">{{item.goodsName || ""}}</div>
					<div class="text2">{{item.manufacturerName}}</div>
					<div class="text3">有效期：{{item.medicineDrugs.validityDate}}</div>
					<div class="pricebox" v-if="listType=='tejia'">
						<div class="nowprice">¥<span>{{item.finalPrice || "详情可知"}}</span></div>
						<div class="oldprice">¥{{item.oriPrice || "详情可知"}}</div>
						<div class="btn1">立即抢购</div>
					</div>
					<div class="teamtips" v-if="listType=='team'">
						<div class="tip1">{{item.groupNum || 1}}人团</div>
						<div class="tip2">已经拼{{item.salesVolume || 0}}</div>
					</div>
					<div class="pricebox" v-if="listType=='team'">
						<div class="nowprice">成团价¥<span>{{item.finalPrice || "详情可知"}}</span></div>
						<div class="oldprice">¥{{item.oriPrice || "详情可知"}}</div>
						<div class="btn1">立即拼团</div>
					</div>
					<div class="teamtips" v-if="listType=='exchange'">
						<div class="tip1"></div>
						<div class="tip2">已兑换{{item.salesVolume || 0}}</div>
					</div>
					<div class="pricebox" v-if="listType=='exchange'">
						<div class="nowprice">积分兑换<span>{{item.finalPrice || "详情可知"}}</span></div>
						<div class="oldprice">¥{{item.oriPrice || "详情可知"}}</div>
						<div class="btn1">立即兑换</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name:"goods-list",
		props: {
			goodsList: {
				type: Array,
				default(){
					return []
				}
			},
			type: {
				type: Number,
				default(){
					return 0
				}
			},
			listType: {
				type: String,
				default(){
					return ""
				}
			},
		},
		data() {
			return {
				
			};
		},
		methods:{
			//专区
			navTozhuanqu(index){
				this.$router.push({ path: '/goodsDetail', query: {uid: this.goodsList[index].id,type:this.listType }})
			},
		},
	}
</script>

<style lang="scss" scoped>
.goodslist{
	width: 100%;
	box-sizing: border-box;
}
</style>

<template>
	<div class="page" style="background-color: #FFCC67;">
		<!-- 顶部菜单 -->
		<topBar :curIndex="2"></topBar>
		<div class="xc_top_banner_box">
			<div class="banner" v-if="bannerList.length>0">
				<img class="img" :src="bannerList[0].imageUrl" @click="$util.windowOpen(bannerList[0].redirectUrl)"/>
			</div>
		</div>
		<div class="block20"></div>
		<block v-if="isLogin">
		<goodsListC :type="1" :goodsList="dataList" :listType="'team'"></goodsListC>
		</block>
		<block v-if="!isLogin">
		<goodsListCn :type="1" :goodsList="dataList" :listType="'team'"></goodsListCn>
		</block>
		<div class="block20"></div>
		<div class="pagination_box_white w1240">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
		<div class="block40"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import  topBar  from "../components/topBar/topBar.vue";
import  goodsListC from "../components/goods-list/goods-list.vue";
import  goodsListCn from "../components/goods-list/goods-list-nonlogin.vue";
export default {
	components: {
		topBar,
		goodsListC,
		goodsListCn
	},
	data() {
		return {
			uid:"",
			bannerList:[],
			countdown: ["0", "0", "0", "0", "0", "0"], //倒计时
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
		};
	},
	mounted: function() {
		this.getwheelPage(20)
		this.getgoodsList()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName']),
	},
	methods: {
		//获取轮播图
		getwheelPage(type) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 8
				params["type"] = type
				this.$http.post('AdvertModule/wheel-advert/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							_this.bannerList = records
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//商品列表
		getgoodsList() {
			var _this = this
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			this.$http.post('medicine/medicineDrugsGroup/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					_this.total = res.data.total
					records.forEach((item,index)=>{
						item["finalPrice"] = item.price
					})
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
